import { setContext as SentrySetContext } from '@sentry/nextjs'
import { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Image from 'next/image'
import { destroyCookie } from 'nookies'

import TadaServiceContext from '../../../components/context/tada_service_context'
import {
   changeModal,
   setCardInfo,
   setCardNumber,
   setMinTotalOrder,
   setProfileInfo,
   setShopId,
   setShoppingList,
   setUserToken
} from '@redux/actions'
import useSetMarket from '../useSetMarket'
import { setCookie, syncShoppingList } from '@utils'
import LoginForm from '@components/common/forms/login_form'
import { DEFAULT_SHOP_ID } from '@static/constants'
import s from './useAccount.module.scss'

export default function useAccount() {
   const dispatch = useDispatch()
   const tadaService = useContext(TadaServiceContext)
   const { setMarket, saveMarketLocal } = useSetMarket()

   const marketsList = useSelector(state => state.marketsList)
   const choosenCity = useSelector(state => state.choosenCity)
   const choosenAddress = useSelector(state => state.choosenAddress)
   const choosenShopId = useSelector(state => state.choosenShopId)
   const { isNPShown, choosenCityNP, choosenAddressNP } = useSelector(state => state)
   const profileInfo = useSelector(state => state.profileInfo)
   const shoppingList = useSelector(state => state.shoppingList)

   const setCard = (cardNumber) => {
      dispatch(setCardNumber(cardNumber))
   }

   const setUserData = async (cardNumber, token, updateShop = false) => {
      let valid = true

      const persInfo = await tadaService
         .getPersonalInfo(token)
         .then(res => {
            const formattedProfileInfo = { ...res }
            const DEFAULT_BIRTHDAY = '0001-01-01'

            // User does not have selected birthday
            if (res.birthday === DEFAULT_BIRTHDAY) {
               formattedProfileInfo.birthday = ''
            }

            dispatch(setProfileInfo(res))
            updateShop ? updateUserShop(token) : setUserShop(res.shop)
            return res
         })
         .catch(e => {
            valid = false
            tadaService.logError(e)
         })

      await Promise.allSettled([
         tadaService
            .getCardInfo(token)
            .then(res => dispatch(setCardInfo(res)))
            .catch(e => {
               valid = false
               tadaService.logError(e)
            }),
         tadaService
            .getCartList(token)
            .then(cartList => {
               const synchronizedShoppingList = syncShoppingList(cartList, shoppingList)

               dispatch(setMinTotalOrder(cartList.minTotalOrder))
               dispatch(setShoppingList(synchronizedShoppingList))
            })
            .catch(e => {
               valid = false
               tadaService.logError(e)
            })
      ])

      if (valid) {
         dispatch(setUserToken(token))
         setCard(cardNumber)
         setCookie(
            null, 'user',
            JSON.stringify({ token, cardNumber }),
            { maxAge: 31 * 24 * 60 * 60 } // 31 days
         )

         SentrySetContext('userData', {
            isAuthed: Boolean(token),
            phone: profileInfo?.phone,
            cardNumber,
            choosenCity,
            choosenAddress
         })
      } else {
         destroyCookie(null, 'user')

         SentrySetContext('userData', {
            choosenCity,
            choosenAddress
         })
      }

      return valid && persInfo
   }

   const updateUserShop = (token) => {
      let city, address
      if (!isNPShown) {
         city = marketsList.find(({ value }) => value === choosenCity) || null
         address = city?.items.find(({ shopId }) => +shopId === +choosenShopId) || null
      }
      setMarket(city, address, token, choosenCityNP, choosenAddressNP, isNPShown)
   }

   const setUserShop = shop => {
      const userShopId = shop.shopId || DEFAULT_SHOP_ID
      dispatch(setShopId(userShopId))
      /* if (!isNaN(+userShopId) && +userShopId !== 8) {
         const city = marketsList.find(({ items }) => items.find(({ shopId }) => +shopId === +userShopId))
         const address = city.items.find(({ shopId }) => +shopId === +userShopId)
         saveMarketLocal(city, address)
      } */
   }

   const toAuthorization = (config = {}) => {
      const {
         title = 'Вхід',
         showSuccessText = false,
         updateUserShop = false,
         onSuccessLoggedIn = () => { }
      } = config

      return dispatch(changeModal({
         title,
         inner: <LoginForm
            showSuccessText={ showSuccessText }
            updateUserShop={ updateUserShop }
            onSuccessLoggedIn={ onSuccessLoggedIn }
         />,
         extra: (
            <div className={ s.login_img }>
               <Image
                  src="/images/common/dino/login.svg"
                  width={ 160 }
                  height={ 203 }
                  alt=""
               />
            </div>
         )
      }))
   }

   return {
      setCard,
      setUserData,
      setUserShop,
      toAuthorization,
   }
}
